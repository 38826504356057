import { lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
const Home = lazy(() => import("../pages/Home"));
const Company = lazy(() => import("../pages/Company"));
const Team = lazy(() => import("../pages/Team"));
const NftMarketplace = lazy(() => import("../pages/NftMarketplace"));
const Fractional = lazy(() => import("../pages/Fractional"));
const SecurityAudit = lazy(() => import("../pages/SecurityAudit"));
const Contact = lazy(() => import("../pages/Contact"));
const Roadmap = lazy(() => import("../pages/Roadmap"));
const Tokenomics = lazy(() => import("../pages/Tokenomics"));
const OurProducts = lazy(() => import("../pages/OurProducts"));
const HowToBuyToken = lazy(() => import("../pages/HowToBuyToken"));
const AugmentedReality = lazy(() => import("../pages/AugmentedReality"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("../pages/TermsOfUse"));
const SmartContracts = lazy(() => import("../pages/SmartContracts"));

const Router: React.FC = () => {
	return (
		<>
			<BrowserRouter>
			<Suspense fallback={<div style={{ textAlign: "center", marginTop: "1rem" }}>Loading...</div>}>
				<Routes>
					<Route path="/company" element={<Company />} />
					<Route path="/team" element={<Team />} />
					<Route
						path="/nft-marketplace"
						element={<NftMarketplace />}
					/>
					<Route path="/fractional" element={<Fractional />} />
					<Route path="/security" element={<SecurityAudit />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/roadmap" element={<Roadmap />} />
					<Route path="/tokenomics" element={<Tokenomics />} />
					<Route path="/our-products" element={<OurProducts />} />
					<Route path="/how-to-buy-ousetoken" element={<HowToBuyToken />} />
					<Route path="/augmented-reality" element={<AugmentedReality />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/terms-of-use" element={<TermsOfUse />} />
					<Route path="/smart-contracts" element={<SmartContracts />} />
					<Route path="/" element={<Home />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</Suspense>
			</BrowserRouter>
		</>
	);
};

export default Router;
